import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => {
	return {
		companyNameText: {
			fontSize: 24,
			borderBottom: "4px solid #000000",
		},
        companyNameWrap: {
			width: "100%",
			textAlign: "center",
			padding: "0 0 24px",
		},
	};
});
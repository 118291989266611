import React from "react";
import { createCompanyView, ViewComponent } from "../../_lib/view";
import { CTAProvider } from "./_lib/context";
import { CTADisplay } from "./_lib/CTADisplay";

const CTAView: ViewComponent = (props) => {
	return (
		<CTAProvider {...props}>
			<CTADisplay {...props} />
		</CTAProvider>
	);
};

export default createCompanyView(CTAView, {
	title: "CTA",
	internalScroll: true,
	internalPad: true,
});

import React from "react";

import { Box } from "@material-ui/core";

import { Typography } from "../Typography";

import { useStyles } from "./styles";

const CompanyNameTitle = ({ companyName }: { companyName: string }) => {
	const styles = useStyles();
	return (
		<Box className={styles.companyNameWrap}>
			<Typography className={styles.companyNameText} variant="caption">
				{companyName}
			</Typography>
		</Box>
	);
};

export default CompanyNameTitle;

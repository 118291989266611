import { createPrivateView, ViewComponent } from "~/model/view/private";
import { Layout } from "./Layout";

export const createCompanyView: typeof createPrivateView = (
	component,
	props = {},
) => {
	return createPrivateView(component, {
		...props,
		showBack: true,
		backTo: "/admin/dealer-management/",
		title: "Edit Company",
		layoutProps: {
			topNav: {
				sub: Layout,
			},
		},
	});
};

export type { ViewComponent };

export type Alignment = "left" | "center" | "right";
export type FlexAlign = "flex-start" | "center" | "flex-end";

export const convertAlignToFlex = (
	defaultAlign: Alignment,
	alignment?: Alignment,
): FlexAlign => {
	const checkVal = alignment || defaultAlign;
	switch (checkVal) {
		case "left":
			return "flex-start";
		case "center":
			return "center";
		case "right":
			return "flex-end";
	}
};

import React, { Fragment, useMemo } from "react";

import { useAuth } from "@api/auth";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { Table } from "~/components/Table";
import { Redirect } from "~/components/Redirect";

import { GlobalLoader } from "@settings/GlobalLoader";
import { ViewComponent } from "@settings/view";

import { ctaColumns } from "./columns";
import { useCTAContext } from "./context";
import { Typography } from "~/components/Typography";
import CompanyNameTitle from "~/components/CompanyNameTitle";

const useStyles = makeStyles(() => ({
	content: {
		position: "relative",
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
	},
	ctaContent: {
		width: "100%",
		minHeight: "100%",
		overflow: "hidden",
		display: "flex",
		flexFlow: "column nowrap",
		position: "relative",
	},
	overrideDisplay: {
		display: "flex",
		padding: "5%",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		textAlign: "center",
	},
}));

export const CTADisplay: ViewComponent = ({ location }) => {
	const { user } = useAuth();
	const { displayGroup, loading, state } = useCTAContext();
	const styles = useStyles();

	const content = useMemo(() => {
		if (!user?.autocorpAdmin) {
			return <Redirect to="/leads/" />;
		}

		if (loading) {
			return <GlobalLoader />;
		}

		const rows = displayGroup?.rows || [];
		rows.sort((a, b) => (`${a.values[0]}` < `${b.values[0]}` ? -1 : 1));
		return (
			<Fragment>
				<CompanyNameTitle companyName={state?.name} />
				<Table
					compact
					columns={ctaColumns}
					rows={rows}
					loading={loading}
					emptyMessage={"This company does not have any ctas."}
				/>
			</Fragment>
		);
	}, [displayGroup, loading, user]);

	return (
		<Box className={styles.content}>
			<Box className={styles.ctaContent}>{content}</Box>
		</Box>
	);
};

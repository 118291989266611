import React from "react";

import { LayoutComponent } from "~/model/layout";
import { PrivatePaths } from "~/model/paths";
import { ITabProps } from "~/components/Tab/types";
import TabbedNav from "~/components/TabbebNav";

export const Layout: LayoutComponent<never> = ({
	location,
	pageProps: { companyId },
}) => {
	const tabs: ITabProps[] = [
		{
			location,
			title: "CTAs",
			path: `/admin/dealer-management/${companyId}/ctas` as PrivatePaths,
		},
		{
			location,
			title: "Products",
			path: `/admin/dealer-management/${companyId}/products` as PrivatePaths,
		},
		{
			location,
			title: "Integrations",
			path: `/admin/dealer-management/${companyId}/integrations` as PrivatePaths,
		},
		{
			location,
			title: "Channels",
			path: `/admin/dealer-management/${companyId}/channels` as PrivatePaths,
		},
	];

	return <TabbedNav tabs={tabs} />;
};
